import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Body from "./Body";
import Footer from "../../components/Footer";
import Contact from "../Contact";
import MobileHeader from "../../components/Header/MobileHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { moveToId } from "../../utils";
import GridPattern from "../../components/GridPattern";
import TopBar from "../../components/TopBar";

export default function Home() {
  const [isContactVisible, setContactVisible] = useState<boolean>(false);
  // 현재 창의 너비를 저장할 상태
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    function initSearchParams(url: string) {
      searchParams.set(url, "false");
      const newURL = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, "", newURL);

      navigate("/");
    }

    if (searchParams.get("moveToUserOutput") === "true") {
      moveToId("#userOutput", false);
      initSearchParams("moveToUserOutput");
    } else if (searchParams.get("moveToMission") === "true") {
      moveToId("#mission", false);
      initSearchParams("moveToMission");
    } else if (searchParams.get("moveToKeyValue") === "true") {
      moveToId("#keyValue", false);
      initSearchParams("moveToKeyValue");
    }
  }, [location.search]);

  // 화면 크기가 변경될 때마다 창의 너비 업데이트
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 부드럽게 스크롤
    });
  };

  const toggleBodyVisibility = () => {
    setContactVisible(false);
  };

  const toggleContactVisibility = () => {
    setContactVisible(!isContactVisible);
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <Body />
    </div>
  );
}
