import Spacer from "../../components/Spacer";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { moveToId } from "../../utils";

import MobileMenu from "./MobileMenu";
import NavBarMenuButton from "./NavBarMenuButton";

interface MobileHeaderMenuProps {
  setVisible: (value: boolean) => void;
}

export default function MobileHeaderMenu(props: MobileHeaderMenuProps) {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    // 스크롤 방향 확인
    if (prevScrollPos > currentScrollPos) {
      setVisible(true); // 스크롤을 올릴 때 Header 표시
    } else {
      setVisible(false); // 스크롤을 내릴 때 Header 숨김
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <MobileMenu>
      <NavBarMenuButton
        fontWeight={600}
        onClick={() => {
          navigate("/introduction");
          props.setVisible(false);
        }}
      >
        소개
      </NavBarMenuButton>
      {/* <Spacer width="3.5rem" /> */}
      <NavBarMenuButton
        fontWeight={600}
        onClick={() => {
          navigate("/product");
          props.setVisible(false);
        }}
      >
        프로덕트
      </NavBarMenuButton>
      {/* <Spacer width="3.5rem" /> */}
      <NavBarMenuButton
        fontWeight={600}
        onClick={() => {
          navigate("contact");
          props.setVisible(false);
        }}
      >
        비지니스 문의
      </NavBarMenuButton>
    </MobileMenu>
  );
}
