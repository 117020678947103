import styled from "styled-components";

const CompanyInfo = styled.div<{ fontSize: number }>`
  color: black;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
  display: flex;
  justify-content: left;
  align-items: left;
  padding-left: 1.75rem;
  line-height: 170%;
  text-align: left;
  font-family: Pretendard, sans-serif;
`;

export default CompanyInfo;
