import React, { useState, useEffect, useRef } from "react";
import UserInput from "./UserInput";
import Vision from "./Vision";
import EndPage from "./EndPage";

export default function Body() {
  return (
    <>
      <UserInput />
      <Vision />
      <EndPage />
    </>
  );
}
