export default function GridPattern() {
  return (
    <div
      style={{
        width: "100%",
        backgroundSize: "8px 9px",
        backgroundImage:
          "linear-gradient(rgba(0, 0, 0, 1) 1px, transparent 1px), linear-gradient(90deg, rgba(0, 0, 0, 1) 1px, transparent 1px)",
        backgroundColor: "white",
        height: "37px",
      }}
    />
  );
}
