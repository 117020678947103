import styled from "styled-components";

interface ContactButtonProps {
  fontSize: number;
}

const ContactButton = styled.button<ContactButtonProps>`
  background: transparent;
  border: 1px solid black;
  color: black;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 700;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  cursor: pointer;
  font-family: Pretendard, sans-serif;
`;

export default ContactButton;
