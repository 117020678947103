import { ReactComponent as Logo } from "../../assets/icons/ConpapaLogo.svg";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";
import { P16_600 } from "../../assets/fonts";
import MobileHeaderMenu from "./MobileHeaderMenu";
import MobileMenuButton from "./MobileMenuButton";

import { ReactComponent as GridMenu } from "../../assets/icons/GridMenu.svg";

export default function Header() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 부드럽게 스크롤
    });
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    // 스크롤 방향 확인
    if (currentScrollPos < 10 || prevScrollPos > currentScrollPos) {
      setVisible(true); // 스크롤을 올릴 때 Header 표시
    } else {
      setVisible(false); // 스크롤을 내릴 때 Header 숨김
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // 윈도우의 크기가 변경될 때마다 이벤트 리스너를 등록합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 unmount될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [menuVisible, setMenuVisible] = useState(false);

  function handleMenuVisibility() {
    setMenuVisible(!menuVisible);
  }

  const location = useLocation();

  if (
    location.pathname.includes("/openalarm") ||
    location.pathname.includes("/openAlarm")
  ) {
    return null;
  }

  return (
    <Container visible={visible}>
      <Logo
        width="117.287px"
        height="24.316px"
        cursor="pointer"
        onClick={() => {
          navigate("/");
          scrollToTop();
        }}
      />
      {windowWidth > 670 ? (
        <ButtonListContainer>
          <ButtonContainer onClick={() => navigate("/introduction")}>
            <P16_600 color="#FFFFFF">소개</P16_600>
          </ButtonContainer>
          <ButtonContainer onClick={() => navigate("/product")}>
            <P16_600 color="#FFFFFF">프로덕트</P16_600>
          </ButtonContainer>
          <ButtonContainer onClick={() => navigate("/contact")}>
            <P16_600 color="#FFFFFF">비지니스 문의</P16_600>
          </ButtonContainer>
        </ButtonListContainer>
      ) : (
        <>
          <MobileMenuButton onClick={handleMenuVisibility}>
            <GridMenu width="1.125rem" height="1.125rem" />
          </MobileMenuButton>
          {menuVisible && visible && (
            <MobileHeaderMenu setVisible={setMenuVisible} />
          )}
        </>
      )}
    </Container>
  );
}

interface ContainerProps {
  visible: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0px 27px 0px 27px;
  height: 66px;

  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: ${(props) => (props.visible ? "0" : "-66px")};
  left: 0;
  right: 0;
  z-index: 1000;
  transition: top 0.3s ease-in-out;
  backdrop-filter: blur(10px);
`;

const ButtonListContainer = styled.div`
  display: inline-flex;
  align-items: flex-start;
  gap: 38px;
`;

const ButtonContainer = styled.div`
  :hover {
    font-weight: 700;
    cursor: pointer;
  }
`;
