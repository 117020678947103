import Text from "../../../components/Text";
import Spacer from "../../../components/Spacer";
import { useState, useEffect } from "react";
import axios from "axios";
import UserSentence from "../../../components/UserSentence/UserSentence";
import AddButton from "../../../components/UserInput/AddButton";
import UserTextInput from "../../../components/UserInput/UserTextInput";

export default function UserInput() {
  const [adjective, setAdjective] = useState<string>("");
  const [noun, setNoun] = useState<string>("");
  const [sentence, setSentence] = useState<string>("");
  const [texts, setTexts] = useState<{ id: string; text: string }[]>([]);

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    // 허용하지 않을 문자들을 설정합니다.
    const forbiddenChars: string[] = ["{", "}", "[", "]", "="];

    // 입력된 키를 가져옵니다.
    const key: string = e.key;

    // 허용하지 않을 문자가 입력되었을 때 입력을 차단합니다.
    if (forbiddenChars.includes(key)) {
      e.preventDefault();
      alert(`'${key}' 문자는 입력할 수 없습니다.`);
    }

    if (e.key === "Enter") {
      addSentence();
    }
  };

  async function getWords() {
    try {
      const adjRes = await axios.get(
        "https://1x1ypgfwgg.execute-api.ap-northeast-2.amazonaws.com/default"
      );
      const adjData = JSON.parse(adjRes.data.body);
      setAdjective(adjData.dailyAdjective);

      const nounRes = await axios.get(
        "https://0oots4i9pl.execute-api.ap-northeast-2.amazonaws.com/default"
      );

      const nounData = JSON.parse(nounRes.data.body);
      setNoun(nounData.dailyNoun);
    } catch (err) {
      console.error("오류가 발생했습니다 : ", err);
    }
  }

  async function addSentence() {
    if (sentence.trim() === "") {
      alert("글을 적지 않으셨네요!");

      return;
    }

    try {
      const res = await axios.post(
        "https://0goztpje96.execute-api.ap-northeast-2.amazonaws.com/default",
        {
          sentence: sentence,
          keyword: adjective + " " + noun,
        }
      );

      setSentence("");
      getItems();
      alert("업로드 완료!");
    } catch (err) {
      alert("오류가 발생했습니다 : " + err);
    }
  }

  useEffect(() => {
    getWords();
  }, [adjective, noun]);

  const [items, setItems] = useState<string[]>([]);

  async function getItems() {
    try {
      const response = await axios.get(
        "https://0goztpje96.execute-api.ap-northeast-2.amazonaws.com/default"
      );

      const data = JSON.parse(response.data.body);
      setItems(data.sentences);
    } catch (err) {
      console.error("오류가 발생했습니다 : " + err);
    }
  }

  useEffect(() => {
    getItems();
  }, []);

  useEffect(() => {
    const updatedTexts = items.map((text) => ({
      id: crypto.randomUUID(),
      text,
    }));

    setTexts(updatedTexts);
  }, [items]);

  const [isPC, setPC] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 640) {
      setPC(true);
    } else {
      setPC(false);
    }
  }, [window.innerWidth]);

  return (
    <div
      id="user-input"
      style={{
        backgroundColor: "black",
        color: "white",
        height: isPC ? "74.0625rem" : "35.125rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: isPC ? "10.9375rem" : "5.46875rem",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          color="white"
          size={20}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            paddingLeft: 27,
            alignSelf: "flex-start",
          }}
        >
          오늘의 단초,
        </Text>
        <Spacer height="1.4375rem" />
        <div
          style={{
            border: "1px solid #FFFFFF",
            width: "84vw",
            height: isPC ? "10.5rem" : "5.375rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              color: "white",
              fontSize: isPC ? "6rem" : "2rem",
              fontWeight: 400,
              fontFamily: "Nanum Myeongjo",
            }}
          >
            {adjective}
          </div>
          <div style={{ width: "8vw" }} />
          <div
            style={{
              color: "white",
              fontSize: isPC ? "6rem" : "2rem",
              fontWeight: 400,
              fontFamily: "Nanum Myeongjo",
            }}
          >
            {noun}
          </div>
        </div>
        <div style={{ height: isPC ? "4.4375rem" : "0.875rem" }} />
        <UserTextInput
          fontSize={isPC ? 20 : 14}
          value={sentence}
          onKeyPress={handleKeyPress}
          onChange={(e) => setSentence(e.target.value)}
          placeholder="| 생각나는 대로 글을 써봐요"
        />
        <Spacer height={isPC ? "1rem" : "0.5rem"} />
        <div
          style={{
            width: "64vw",
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
            alignSelf: "flex-end",
            paddingRight: "10vw",
          }}
        >
          <AddButton fontSize={isPC ? 20 : 14} onClick={addSentence}>
            추가
          </AddButton>
        </div>
      </div>
      <div style={{ height: isPC ? "75.375rem" : "18.84375rem" }} />
      <UserSentence texts={texts} />
    </div>
  );
}
