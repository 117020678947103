import React, { useEffect, useState, useRef } from "react";
import Text from "../../../components/Text";
import Spacer from "../../../components/Spacer";
import { ReactComponent as DoubleQuotationStart } from "../../../assets/icons/DoubleQuotationStart.svg";
import { ReactComponent as DoubleQuotationEnd } from "../../../assets/icons/DoubleQuotationEnd.svg";

export default function Vision() {
  const prevScrollY = useRef(0);
  const [scale, setScale] = useState(1.0);
  const [top, setTop] = useState<number>(0);

  const [bottom, setBottom] = useState<number>(0);
  const [opacity, setOpacity] = useState<number>(0);
  const maxScale = 30;

  const [isFixed, setFixed] = useState(false);
  const [textHeight, setTextHeight] = useState<number>(0);

  const [heightAsPixel, setHeightAsPixel] = useState<number>(0);
  let scroll = 0;

  useEffect(() => {
    setOpacity((scale - 1) / (maxScale - 1));
  }, [scale]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleResize() {
    // when resize happens, update top and bottom value
    updateTopBottom();
  }

  function updateTopBottom() {
    const element = document.querySelector("#vision") as HTMLElement | null;

    if (element == null) return;

    const offsetTop = element.offsetTop;
    setTop(offsetTop);
    const offsetBottom = element.offsetTop + window.innerHeight;
    setBottom(offsetBottom);

    const textElement = document.querySelector(
      "#visionText"
    ) as HTMLElement | null;
    if (textElement == null) return;

    setTextHeight(textElement.clientHeight);

    setHeightAsPixel(window.innerHeight);
  }

  useEffect(() => {
    updateTopBottom();
  }, []);

  function getHexFromNumber(num: number) {
    if (num <= 0) return "00";
    else if (num >= 1) return "FF";
    else {
      const intBase255 = Math.floor(num * 255);
      const hexValue = intBase255.toString(16);
      return hexValue.length === 1 ? "0" + hexValue : hexValue;
    }
  }

  const handleScroll = () => {
    if (top == null || bottom == null) return;

    let goingUp = false;

    const currentScrollY = window.scrollY;
    if (prevScrollY.current < currentScrollY) goingUp = false;
    else if (prevScrollY.current > currentScrollY) goingUp = true;

    if (currentScrollY >= top && currentScrollY <= bottom) {
      // start to scale
      setFixed(true);
      setScale(
        1.0 + ((maxScale - 1.0) * (currentScrollY - top)) / (bottom - top)
      );
    } else if (currentScrollY < top) {
      setFixed(false);
      setScale(1.0);
    } else if (currentScrollY > bottom) {
      setFixed(false);
      setScale(maxScale);
    }

    prevScrollY.current = currentScrollY;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    updateTopBottom();
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scale, top, bottom, isFixed]);

  const [isPC, setPC] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 940) {
      setPC(true);
    } else {
      setPC(false);
    }
  }, [window.innerWidth]);

  return (
    <div id="vision">
      <div
        id="mission"
        style={{
          backgroundColor: `#${getHexFromNumber(opacity)}${getHexFromNumber(
            opacity
          )}${getHexFromNumber(opacity)}`,
          height: heightAsPixel,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            transform: `scale(${scale})`, // scale 값을 적용하여 줌인 또는 줌아웃합니다.
            transformOrigin: "50% 50%", // 확대/축소 중심 설정
            transition: "transform 0.3s ease-out",
            position: isFixed ? "fixed" : "static",
            top: heightAsPixel * 0.5 - 0.5 * textHeight,
            backgroundColor: "transparent",
            display: "flex",
          }}
        >
          <DoubleQuotationStart
            fill="#fff"
            width={isPC ? 22 : 14}
            height={isPC ? 22 : 14}
            style={{
              alignSelf: "flex-start",
            }}
          />
          <Spacer width="1.0625rem" />
          <div id="visionText">
            <Text
              color="white"
              size={isPC ? 48 : 30}
              weight={400}
              fontFamily="Nanum Myeongjo"
              style={
                isPC
                  ? {}
                  : {
                      textAlign: "center",
                    }
              }
            >
              당신의 이야기에서&nbsp;
              {!isPC && (
                <>
                  <br />
                </>
              )}
              모두의 콘텐츠로
            </Text>
          </div>
          <Spacer width={isPC ? "1.0625rem" : ""} />
          <DoubleQuotationEnd
            fill="#fff"
            width={isPC ? 22 : 14}
            height={isPC ? 22 : 14}
            style={{
              display: "flex",
              justifyContent: "right",
              alignSelf: "flex-end",
            }}
          />
        </div>
      </div>
      <div
        id="emptySpace"
        style={{
          height: `${bottom - top}px`,
          backgroundColor: `#${getHexFromNumber(opacity)}${getHexFromNumber(
            opacity
          )}${getHexFromNumber(opacity)}`,
        }}
      />
    </div>
  );
}
