import styled from "styled-components";
import * as COLORS from "../assets/colors";
import GridPattern from "./GridPattern";
import { useLocation } from "react-router-dom";

export const Container = styled.div`
  width: 100%;
  background: ${COLORS.Black};
  height: 66px;
`;

export default function TopBar() {
  const location = useLocation();
  if (
    location.pathname.includes("/openalarm") ||
    location.pathname.includes("/openAlarm")
  ) {
    return null;
  }

  return (
    <>
      <Container />
      <GridPattern />
    </>
  );
}
