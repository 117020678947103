import styled from "styled-components";

interface NavBarMenuButtonProps {
  fontWeight: number;
}

const NavBarMenuButton = styled.button<NavBarMenuButtonProps>`
  background: transparent;
  color: white;
  font-size: 16px;
  font-weight: ${(props) => props.fontWeight};
  font-family: Pretendard, sans-serif;
  cursor: pointer;
  border: none;
  outline: none;

  &:hover {
    background: transparent;
    color: #707070;
  }

  &:active {
    background: transparent;
  }
`;

export default NavBarMenuButton;
