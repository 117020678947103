import styled from "styled-components";

interface AddButtonProps {
  fontSize: number;
}

const AddButton = styled.button<AddButtonProps>`
  background: transparent;
  color: white;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 300;
  font-family: Pretendard, sans-serif;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    font-weight: 700;
  }
`;

export default AddButton;
