import styled from "styled-components";

interface SpacerProp {
  width?: string;
  height?: string;
}

const Spacer = styled.div<SpacerProp>`
  width: ${(props) => (props.width ? props.width : "0px")};
  height: ${(props) => (props.height ? props.height : "0px")};
`;

export default Spacer;
