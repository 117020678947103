import { useEffect, useState } from "react";
import Spacer from "../Spacer";
import CompanyInfo from "./CompanyInfo";
import FooterTop from "./FooterTop";
import GridPattern from "../GridPattern";
import { useLocation } from "react-router-dom";

export default function Footer() {
  const [isPC, setPC] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // 윈도우의 크기가 변경될 때마다 이벤트 리스너를 등록합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 unmount될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 480) {
      setPC(true);
    } else {
      setPC(false);
    }
  }, [windowWidth]);

  const location = useLocation();

  if (
    location.pathname.includes("/openalarm") ||
    location.pathname.includes("/openAlarm")
  ) {
    return null;
  }

  return (
    <>
      <GridPattern />
      <div
        style={{
          backgroundColor: "white",
          paddingTop: "2.1875rem",
        }}
      >
        <FooterTop />
        <Spacer height={isPC ? "3.4375rem" : "0.25rem"} />
        <CompanyInfo fontSize={isPC ? 16 : 7}>
          서울 강남구 테헤란로87길 36 도심공항타워 24F
          <br /> contact@conpapa.co
          <br /> Conpapa© All Rights Reserved.
        </CompanyInfo>
        <Spacer height={isPC ? "6.8125rem" : "3.875rem"} />
      </div>
    </>
  );
}
