import Spacer from "../../../components/Spacer";
import Text from "../../../components/Text";
import Logo from "../../../assets/images/textLogo.png";
import Image from "../../../components/Image";
import styled from "styled-components";
import { Pretendard } from "../../../assets/fonts";
import { useNavigate } from "react-router-dom";
import * as COLORS from "../../../assets/colors";
import { useEffect, useState } from "react";

export default function EndPage() {
  const navigate = useNavigate();
  const [isPC, setPC] = useState(false);

  useEffect(() => {
    if (window.innerWidth > 1040) {
      setPC(true);
    } else {
      setPC(false);
    }
  }, [window.innerWidth]);

  return (
    <div
      id="end-page"
      style={{
        backgroundColor: "white",
      }}
    >
      <div
        style={{
          width: isPC ? "61.875rem" : "19rem",
          margin: "0 auto",
        }}
      >
        <Text
          color="black"
          size={isPC ? 26 : 20}
          weight={isPC ? 700 : 600}
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            paddingRight: isPC ? 100 : 0,
            lineHeight: "170%",
            letterSpacing: "-0.84px",
          }}
        >
          누구나 한 번쯤 떠올리는
          <br /> 머릿속 흥미로운 이야기들
        </Text>
        <Spacer height={isPC ? "40px" : "16px"} />
        <Text
          color="black"
          size={isPC ? 26 : 20}
          weight={isPC ? 700 : 600}
          style={{
            textAlign: "left",
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            lineHeight: "170%",
            letterSpacing: "-0.84px",
          }}
        >
          그러한 이야기들을 만나는
          <br /> 새로운 세상을 꿈꿉니다
        </Text>
        <Spacer height={isPC ? "77px" : "38px"} />
        <Image
          src={Logo}
          width={isPC ? "386px" : "159px"}
          height={isPC ? "80px" : "33px"}
        />
        <Spacer height={isPC ? "110px" : "83px"} />
        <ProductButton onClick={() => navigate("/product")}>
          <Pretendard color={COLORS.Black} size={isPC ? 24 : 14} weight={700}>
            프로덕트 보러가기
          </Pretendard>
        </ProductButton>
      </div>
      <Spacer height={isPC ? "24.1875rem" : "12.4375rem"} />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Text
          color="black"
          size={isPC ? 60 : 30}
          weight={400}
          fontFamily="Ultra"
        >
          Hello :)
        </Text>
      </div>
      <Spacer height="7.3125rem" />
    </div>
  );
}

const ProductButton = styled.div`
  display: inline-flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 40px;
  border: 1px solid #747a75;

  cursor: pointer;

  &:hover {
    background: ${COLORS.Gray300};
  }
`;
