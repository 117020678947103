import React, { useState, useEffect, useRef } from "react";
import SentenceBox from "./SentenceBox";
import "./UserSentence.css";

interface UserSentenceProps {
  texts: { id: string; text: string }[];
  speed?: number;
}

const UserSentence: React.FC<UserSentenceProps> = ({
  texts,
  speed = 200000,
}) => {
  return (
    <div className="inner">
      <div className="wrapper">
        <section
          style={{
            display: "flex",
            willChange: "transform",
            //WebkitAnimation: `swipe ${speed}ms linear infinite backwards`,
            animation: `swipe ${speed}ms linear infinite backwards`,
          }}
        >
          {texts.map(({ id, text }, index) => (
            <div className="text" key={id}>
              <SentenceBox id={id} item={text} index={index} />
            </div>
          ))}
        </section>
        <section
          style={{
            display: "flex",
            willChange: "transform",
            //WebkitAnimation: `swipe ${speed}ms linear infinite backwards`,
            animation: `swipe ${speed}ms linear infinite backwards`,
          }}
        >
          {texts.map(({ id, text }, index) => (
            <div className="text" key={id}>
              <SentenceBox id={id} item={text} index={index} />
            </div>
          ))}
        </section>
        <section
          style={{
            display: "flex",
            willChange: "transform",
            //WebkitAnimation: `swipe ${speed}ms linear infinite backwards`,
            animation: `swipe ${speed}ms linear infinite backwards`,
          }}
        >
          {texts.map(({ id, text }, index) => (
            <div className="text" key={id}>
              <SentenceBox id={id} item={text} index={index} />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

export default UserSentence;
