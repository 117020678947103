import styled from "styled-components";

const MobileMenu = styled.div`
  background: rgba(0, 0, 0, 0.6);
  color: #707070;
  height: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  backdrop-filter: blur(10px);
  position: fixed;
  top: 4.125rem;
  left: 0;
  right: 0;
  z-index: 1000;
`;

export default MobileMenu;
