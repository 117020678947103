import Text from "../../components/Text";
import Spacer from "../../components/Spacer";
import axios from "axios";
import React, { useState, useEffect } from "react";
import GridPattern from "../../components/GridPattern";

export default function Contact() {
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  async function submitContact() {
    try {
      const response = await axios.post(
        "https://05yxp48fke.execute-api.ap-northeast-2.amazonaws.com/default",
        {
          title: title,
          content: content,
          email: email,
        }
      );
      console.log(response);
      alert("문의해 주셔서 감사합니다!");

      setTitle("");
      setContent("");
      setEmail("");
    } catch (err) {
      alert("오류가 발생했습니다 : " + err);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const [isPC, setPC] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // 윈도우의 크기가 변경될 때마다 이벤트 리스너를 등록합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 unmount될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 940) {
      setPC(true);
    } else {
      setPC(false);
    }
  }, [windowWidth]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          width: "81vw",
          margin: "0 auto",
          flexDirection: "column",
        }}
      >
        <Spacer height="5.9375rem" />
        <Text
          color="black"
          size={isPC ? 56 : 28}
          weight={400}
          fontFamily="Ultra"
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          Hello :)
        </Text>
        <Spacer height="0.6875rem" />
        <Text
          color="black"
          size={isPC ? 48 : 24}
          weight={900}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            textAlign: isPC ? "center" : "left",
          }}
        >
          당신과 만들어 갈&nbsp;
          {isPC ? (
            <></>
          ) : (
            <>
              <br />
            </>
          )}
          스토리가 기대됩니다!
        </Text>
        <Spacer height="1.9375rem" />
        <Text
          color="black"
          size={isPC ? 24 : 14}
          weight={700}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
            textAlign: "left",
            lineHeight: "170%",
            letterSpacing: "-0.5px",
          }}
        >
          Conpapa에 대해 궁금한 점이나
          <br /> 파트너십 제안, 제품 및 서비스 문의 등이 있다면
          <br /> 아래에 메시지를 남겨주시거나 support@conpapa.co로 연락주세요.
          <br />
          최대한 빠르게 답해드릴게요.
        </Text>
        <Spacer height="4.125rem" />
        <Text
          color="black"
          size={isPC ? 20 : 16}
          weight={700}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          제목
        </Text>
        <Spacer height="0.625rem" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            style={{
              border: "1px solid #000000",
              width: "81vw",
              height: 54,
              paddingLeft: 17,
              fontFamily: "Pretendard, sans-serif",
              fontSize: 18,
              fontWeight: 400,
            }}
            placeholder="제목을 입력해 주세요"
          />
        </div>
        <Spacer height="2.8125rem" />

        <Text
          color="black"
          size={isPC ? 20 : 16}
          weight={700}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          이메일
        </Text>
        <Spacer height="0.625rem" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              border: "1px solid #000000",
              width: "81vw",
              height: 54,
              paddingLeft: 17,
              fontFamily: "Pretendard, sans-serif",
              fontSize: 18,
              fontWeight: 400,
            }}
            placeholder="연락받으실 이메일을 입력해 주세요"
          />
        </div>
        <Spacer height="2.8125rem" />
        <Text
          color="black"
          size={isPC ? 20 : 16}
          weight={700}
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        >
          내용
        </Text>
        <Spacer height="0.625rem" />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            rows={10}
            cols={50}
            placeholder="내용을 입력해 주세요"
            style={{
              border: "1px solid #000000",
              width: "81vw",
              height: 342,
              paddingLeft: 17,
              paddingTop: 17,
              outline: "none",
              fontFamily: "Pretendard, sans-serif",
              resize: "none",
              fontSize: 18,
              fontWeight: 400,
            }}
          />
        </div>
        <Spacer height="0.625rem" />
        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignItems: "left",
          }}
        ></div>
        <div style={{ height: isPC ? "" : "1.25rem" }} />
        <div
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "right",
          }}
        >
          <button
            disabled={title === "" || content === "" || email === ""}
            onClick={submitContact}
            style={{
              backgroundColor:
                title === "" || content === "" || email === ""
                  ? "gray"
                  : "black",
              color:
                title === "" || content === "" || email === ""
                  ? "#dddddd"
                  : "white",
              width: isPC ? "7.5625rem" : "5.625rem",
              height: isPC ? "3.375rem" : "1.875rem",
              border: "none",
              outline: "none",
              fontFamily: "Pretendard, sans-serif",
              fontSize: isPC ? 20 : 14,
              fontWeight: 700,
              cursor:
                title === "" || content === "" || email === ""
                  ? "default"
                  : "pointer",
            }}
          >
            문의하기
          </button>
        </div>
        <Spacer height="10.1875rem" />
      </div>
    </div>
  );
}
