import React, { useEffect, useRef, useState } from "react";

type SentenceBoxProps = {
  id: string;
  item: string;
  index: number;
};

const SentenceBox: React.FC<SentenceBoxProps> = ({ id, item, index }) => {
  return (
    <div
      key={id}
      id={`item-${index}`}
      style={{
        width: window.innerWidth > 480 ? "15rem" : "10rem",
        height: window.innerWidth > 480 ? "12.5rem" : "7.5rem",
        border: "1px solid white",
        color: "white",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "3.125rem 1.5625rem 3.125rem 1.5625rem",
        marginRight: "3.75rem",
        flexShrink: 0,
        flexDirection: "row",
      }}
    >
      <div
        style={{
          fontFamily: "Nanum Myeongjo",
          fontSize: window.innerWidth > 480 ? 28 : 18,
          fontWeight: 400,
          textOverflow: "ellipsis",
          overflow: "hidden",
          display: "-webkit-box",
          WebkitLineClamp: 8,
          WebkitBoxOrient: "vertical",
        }}
      >
        {item}
      </div>
    </div>
  );
};

export default SentenceBox;
