import { useNavigate } from "react-router-dom";
import ContactButton from "../Header/ContactButton";
import FooterLogo from "./FooterLogo";

export default function FooterTop() {
  const navigate = useNavigate();

  return (
    <div
      style={{
        margin: "0 auto",
        display: "flex",
        justifyContent: "space-between",
        paddingLeft: "1.75rem",
        paddingRight: "1.75rem",
      }}
    >
      <FooterLogo />
      <ContactButton
        fontSize={14}
        onClick={() => navigate("/contact")}
        style={{
          height: "1.5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        Contact
      </ContactButton>
    </div>
  );
}
