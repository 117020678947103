import { useEffect, useState } from "react";
import Logo from "../../assets/images/textLogo.png";
import Spacer from "../Spacer";

export default function FooterLogo() {
  const [isPC, setPC] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // 윈도우의 크기가 변경될 때마다 이벤트 리스너를 등록합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 unmount될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 480) {
      setPC(true);
    } else {
      setPC(false);
    }
  }, [windowWidth]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: isPC ? "row" : "column",
      }}
    >
      <img
        src={Logo}
        style={{
          width: "7.375rem",
          height: "1.5625rem",
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
        }}
      />
      {isPC ? <Spacer width="5px" /> : <Spacer height="0.625rem" />}
      <div
        style={{
          display: "flex",
          justifyContent: "left",
          alignItems: "left",
          fontSize: isPC ? 20 : 7,
          fontWeight: 700,
          color: "black",
          fontFamily: "Pretendard, sans-serif",
        }}
      >
        © 2022 - 2024
      </div>
    </div>
  );
}
