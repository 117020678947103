import styled from "styled-components";

interface UserTextInputProps {
  fontSize: number;
}

const UserTextInput = styled.input<UserTextInputProps>`
  background: transparent;
  border: none;
  border-bottom: 1px solid #ffffff;
  width: 64vw;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 300;
  font-family: Pretendard, sans-serif;
  color: white;
  outline: none;
`;

export default UserTextInput;
