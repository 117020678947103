import styled from "styled-components";

interface TextProp {
  color: string;
  size: number;
  weight?: number;
  fontFamily?: string;
}

const Text = styled.div<TextProp>`
  color: ${(props) => props.color};
  font-size: ${(props) => props.size}px;
  font-weight: ${(props) => (props.weight ? props.weight : 500)};
  font-family: ${(props) =>
    props.fontFamily ? props.fontFamily : "Pretendard , sans-serif"};
`;

export default Text;
