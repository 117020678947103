import styled from "styled-components";

interface ImageProp {
  width: number | string;
  height: number | string;
}

const Image = styled.img<ImageProp>`
  width: ${(props) => props.width};
  height: ${(props) => props.height};

  outline: none;
  border: none;
`;

export default Image;
