import styled from "styled-components";

const MobileMenuButton = styled.button`
  width: 1.125rem;
  height: 1.125rem;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
`;

export default MobileMenuButton;
